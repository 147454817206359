import React from "react"
import { graphql } from "gatsby"
import EventList from "../components/EventList"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../styledComponents/section"
import { BackgroundColourBlock } from "../styledComponents/overView"
import LayoutNoStripe from "../components/layoutNoStripe"
import {
  StyledBlogHeader,
} from "../styledComponents/blogArchive"
import ShareTools from "../components/socialLinks"
import BlogList from "../components/BlogList"
import { ReactAudioPlayer } from "../components/reactPlayer"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const ThankyouSignup = ({ data, location }) => {
  
  let posts = data.allWpPost.nodes
  return (
    <>
    <LayoutNoStripe displayNavBorder location={location}>
      <Section>
        <Heading level={1} align="center">
          Free Quantum-Touch eBook &amp; audio
        </Heading>
        <SectionLedeDiv>
        <p>Thank you for signing up to Karina's Newsletter. We have emailed you your FREE Quantum-Touch eBook and audio file from Richard Gordon.</p>
        <p>Here is the audio session with Richard Gordon the founder of Quantum-Touch.</p>
        
        <ReactAudioPlayer  
            style={{'box-shadow':'none','background':'#f5f5f5'}}
            audioURL="https://s3-eu-west-1.amazonaws.com/karinagrant/wp-content/uploads/2014/10/AcceleratedHealing.mp3"
            title="Newsletter Signup : Free Audio session with Richard Gordon"
        />
        </SectionLedeDiv>   
      </Section>

      <BackgroundColourBlock number='2'>
      <StyledBlogHeader>
        <h1>Blog</h1>
        <ShareTools shareTitle="Follow" page="Blog archive" />
      </StyledBlogHeader>
      <BlogList posts={posts} />
      </BackgroundColourBlock>
    </LayoutNoStripe>
    </>
  )
}

export default ThankyouSignup

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "thank-you" }) {
      title
      content
    }
    
    
    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
  }

`
